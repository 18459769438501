// 首字母大写
export function capitalize(str = '') {
  if (!str) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}
export function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

// 安卓还是 ios
export function isIOSorAndroid(): string {
  const userAgent = navigator.userAgent.toLowerCase()

  if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'iOS'
  } else if (/android/.test(userAgent)) {
    return 'Android'
  } else {
    return 'Unknown'
  }
}
