import React, { useEffect, useState } from 'react'

import Alert from './components/alert'
import Bulb from './components/bulb'

import { taponBanner } from '@/api/home'

// setAlertImg()
function Global({ children }) {
  const [alertImg, setAlertImg] = useState('')

  const [bulbImg, setBulbImg] = useState('')

  async function queryTaponBanner(type: number) {
    const banners = await taponBanner({ type })
    if (type === 3) {
      if (banners && banners?.length) {
        const banner = banners.filter(v => v.platforms === 'h5')
        if (banner && banner.length > 0) {
          setAlertImg(banner[0].image)
        }
      }
    } else if (type === 4) {
      if (banners && banners?.length) {
        const banner = banners.filter(v => v.platforms === 'h5')
        if (banner && banner.length > 0) {
          setBulbImg(banner[0].image)
        }
      }
    }
  }
  useEffect(() => {
    // 预留接口请求
    queryTaponBanner(3)
    queryTaponBanner(4)
  }, [])

  return (
    <>
      {children}
      {/* 全局弹窗 */}
      {alertImg && <Alert alertImg={ alertImg }></Alert>}
      {/* 全局灯泡 */}
      {bulbImg && <Bulb bulbImg={ bulbImg }></Bulb>}
    </>
  )
}

export default Global
