import { createSlice } from '@reduxjs/toolkit'
const layoutSlice = createSlice({
  name: 'layout',
  initialState: { activeTab: '' },
  reducers: {
    setActiveTab: (state, { payload }: { payload: { key: string } }) => {
      state.activeTab = payload.key
    },
  },
})

export const { setActiveTab } = layoutSlice.actions
export default layoutSlice.reducer
