import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Mask } from 'antd-mobile'
import { CloseCircleFill } from 'antd-mobile-icons'
import Cookies from 'js-cookie'
import { track } from '@/utils/track'

import './alert.scss'

function Alert(props) {
  const { alertImg } = props
  const [visible, setVisible] = useState(false)
  const { pathname, search } = useLocation()

  const navigate = useNavigate()

  const whiteRouter = ['/sso', '/mine', '/language', '/c-air']
  const targetPath = '/c'

  useEffect(() => {
    setVisible(false)

    if (
      targetPath !== pathname &&
      !whiteRouter.includes(pathname) &&
      !pathname.includes('/bookDetail/') &&
      !Cookies.get('showAlertModal') &&
      alertImg
    ) {
      setVisible(true)
      Cookies.set('showAlertModal', 'true', { expires: 1 })
      track('taponh5-homepopup-view')
    }
  }, [pathname])

  const handleClose = () => {
    setVisible(false)
  }

  return (
    <>
      <Mask visible={ visible }>
        <div className=' alert-wrap'>
          <div
            className='w-300px h-400px rounded-16px overflow-hidden '
            onClick={ () => {
              track('taponh5-homepopup-click')
              navigate(`/c${search}`)
            } }>
            <img className='w-full h-full' src={ alertImg } alt='' />
          </div>
          <CloseCircleFill
            onClick={ () => {
              handleClose()
            } }
            fontSize={ 32 }
            color='#fff'
          />
        </div>
      </Mask>
    </>
  )
}

export default Alert
