import { Store } from '@/store'
import { setToken } from '@/store/token'
import { useDispatch, useSelector } from 'react-redux'

export function useToken() {
  const token = useSelector((store: Store) => store.token.token)
  const dispatch = useDispatch()

  return {
    token,
    setToken: (token: string) => {
      dispatch(setToken(token))
    },
  }
}
