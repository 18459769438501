import { LanguageType } from './i18n'
import * as OpenCC from 'opencc-js/core'
import * as Locale from 'opencc-js/preset'

export function getLanguage(): LanguageType {
  // const storageLanguage = getStorage<LanguageType>('language')
  // if (storageLanguage && storageLanguage.toUpperCase() in LanguageType) {
  //   return storageLanguage
  // }
  // const windowLanguage = window.navigator.language.toUpperCase()
  // if (windowLanguage in LanguageType) {
  //   return LanguageType[windowLanguage]
  // } else {
  //   return LanguageType.EN
  // }

  return (process.env.LANG as LanguageType) || 'en'
}

export const languageTransform = (key?: string, type: 'toCn' | 'toHk' = 'toHk') => {
  if (!key) {
    return ''
  }
  if (getLanguage() === 'hant') {
    // 一般是简体转繁体
    let converter
    if (type === 'toCn') {
      // 这个是需要繁体转简体的情况
      converter = OpenCC.ConverterFactory(Locale.from.hk, Locale.to.cn)
    } else {
      converter = OpenCC.ConverterFactory(Locale.from.cn, Locale.to.hk)
    }

    return converter(key)
  } else if (getLanguage() === 'hans') {
    console.log(12231)
    const converter = OpenCC.ConverterFactory(Locale.from.hk, Locale.to.cn)

    return converter(key)
  }
  return key
}
