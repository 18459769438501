import { getLanguage } from '@/utils/language'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import { en, es, ko, id } from '@/lang'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import ko from '@/locales/ko.json'
import id from '@/locales/id.json'
import pt from '@/locales/pt.json'
import th from '@/locales/th.json'
import hans from '@/locales/zh_Hans.json'
import hant from '@/locales/zh_Hant.json'
import ar from '@/locales/ar.json'
import fr from '@/locales/fr.json'
import de from '@/locales/de.json'
import ja from '@/locales/ja.json'

export enum LanguageType {
  EN = 'en',
  ES = 'es',
  KO = 'ko',
  ID = 'id',
  PT = 'pt',
  TH = 'th',
  HANS = 'hans',
  HANT = 'hant',
  AR = 'ar',
  FR = 'fr',
  DE = 'de',
  JA = 'ja',
}

export const resources = {
  [LanguageType.EN]: { translation: en },
  [LanguageType.ES]: { translation: es },
  [LanguageType.KO]: { translation: ko },
  [LanguageType.ID]: { translation: id },
  [LanguageType.PT]: { translation: pt },
  [LanguageType.TH]: { translation: th },
  [LanguageType.HANS]: { translation: hans },
  [LanguageType.HANT]: { translation: hant },
  [LanguageType.AR]: { translation: ar },
  [LanguageType.FR]: { translation: fr },
  [LanguageType.DE]: { translation: de },
  [LanguageType.JA]: { translation: ja },
}
const language = getLanguage()
i18n
  .use(initReactI18next) // init i18next
  .init({
    resources,
    lng: ['en', 'es', 'ko', 'id', 'pt', 'th', 'hans', 'hant', 'ar', 'fr', 'de', 'ja'].includes(language)
      ? language
      : 'en',
    defaultNS: ['en', 'es', 'ko', 'id', 'pt', 'th', 'hans', 'hant', 'ar', 'fr', 'de', 'ja'].includes(language)
      ? language
      : 'en',
  })
export default i18n
