import { getLanguage, languageTransform } from '@/utils/language'
import { isZh } from '@/utils/constant'

// index.ts
import axios, { AxiosError } from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getCookieToken, setCookieToken } from '@/utils/storage'
import { Toast } from 'antd-mobile'
import i18n from '@/utils/i18n'
import { capitalize } from '@/utils/index'

const isProduction = process.env.env === 'production'
const lang = getLanguage()

let baseURL = import.meta.env.VITE_BASE_URL
if (!isProduction) {
  if (isZh) {
    baseURL = 'https://tapon-api-zh-test.joyread.cc'
  } else if (lang !== 'en') {
    baseURL = `https://tapon-api-${lang}-test.joyread.cc`
  }
}

class Request {
  // axios 实例
  instance: AxiosInstance
  // 基础配置，url和超时时间
  baseConfig: AxiosRequestConfig = {
    baseURL,
    timeout: 20000,
  }

  noMessage = false

  constructor(config: AxiosRequestConfig) {
    // 使用axios.create创建axios实例
    this.instance = axios.create(Object.assign(this.baseConfig, config))

    this.instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // 一般会请求拦截里面加token
        if (config?.headers) {
          if (getCookieToken()) {
            config.headers['Authorization'] = `Bearer ${getCookieToken()}`
          }

          config.headers['x-platform'] = 'web'
          config.headers['x-lang'] = isZh ? 'zh' : lang
          if (isZh) {
            config.headers['x-lang-script'] = capitalize(lang)
          }
          if (config.noMessage) {
            this.noMessage = true
          }
        }
        const params = {}
        for (const key in config?.params || {}) {
          if (![undefined, null].includes(config.params[key])) {
            params[key] = config.params[key]
          }
        }
        config.params = params
        return config
      },
      (err: any) => {
        return Promise.reject(err)
      },
    )

    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        return JSON.parse(languageTransform(JSON.stringify(res.data.data)))
      },
      (err: AxiosError<{ code: number; data: string; msg: string }>) => {
        console.log(err)
        const language = getLanguage()
        if (!this.noMessage) {
          if (err.response?.status === 401) {
            Toast.show({
              icon: 'fail',
              position: 'center',
              content: languageTransform(i18n.getResource(language, 'translation', 'signInFirst')),
              duration: 1000,
              maskClickable: false,
              maskClassName: 'request-toast',
            })
            setCookieToken('')
            setTimeout(() => {
              window.location.href = `${
                window.location.origin
              }/mine?redirect=${window.location.pathname.substring(1)}${encodeURIComponent(window.location.search)}`
            }, 1000)
          } else {
            Toast.show({
              icon: 'fail',
              position: 'center',
              content: languageTransform(
                err.response?.data?.msg || i18n.getResource(language, 'translation', 'lostContact'),
              ),
              duration: 2000,
              maskClickable: false,
              maskClassName: 'request-toast',
            })
          }
        }

        // 这里是AxiosError类型，所以一般我们只reject我们需要的响应即可
        return Promise.reject(err.response)
      },
    )
  }

  // 定义请求方法
  public request(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.instance.request(config)
  }

  public get<T>(url: string, params?: AxiosRequestConfig['params']): Promise<T> {
    return this.instance.get(url, { params })
  }

  public async post<T>(url: string, data?: AxiosRequestConfig['data'], config?: AxiosRequestConfig): Promise<T> {
    return this.instance.post(url, data, config)
  }
}
const request = new Request({})
export default request
