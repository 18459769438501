import { Member } from '@/hooks/useMember'
import { getStorage, setStorage } from '@/utils/storage'
import { createSlice } from '@reduxjs/toolkit'

const memberSlice = createSlice({
  name: 'member',
  initialState: getStorage('member') || {
    name: '',
    avatar: '',
    member_id: 0,
    is_vip: 0,
    token: '',
  },
  reducers: {
    setMember: (state, { payload }: { payload: Member }) => {
      for (const key in payload) {
        state[key] = payload[key]
      }
      setStorage('member', payload)
    },
  },
})

export const { setMember } = memberSlice.actions
export default memberSlice.reducer
