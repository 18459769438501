import { getCookieToken, setCookieToken } from '@/utils/storage'
import { createSlice } from '@reduxjs/toolkit'
import qs from 'querystring'

function initToken() {
  let { search } = window.location
  if (search[0] === '?') search = search.replace(/^\?/, '')
  const { active, token, pkg } = qs.parse(search)
  if (active && Number(pkg) && !token) {
    setCookieToken('')
  }
  return getCookieToken()
}
const tokenSlice = createSlice({
  name: 'token',
  initialState: { token: initToken() },
  reducers: {
    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload
      setCookieToken(payload)
    },
  },
})

export const { setToken } = tokenSlice.actions
export default tokenSlice.reducer
