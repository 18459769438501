import {
  Page,
  TaponBookcase,
  TaponUser,
  TaponUserCostList,
  TaponUserOrderList,
  TaponUserUploadAvatar,
  TaponUserWallet,
  TaponUserWealthCouponList,
  WebMemberCheck,
  WebMemberSearch,
  WebProducts,
} from './types'
import request from '@/utils/request'
import { AxiosRequestConfig } from 'axios'

export function queryTaponUser(params = {}) {
  return request.get<TaponUser>('/tapon/user', params)
}

export function queryTaponUserWallet(params = {}) {
  return request.get<TaponUserWallet>('/tapon/user/wallet', params)
}

export function uploadAvatar(data: FormData) {
  return request.post<TaponUserUploadAvatar>('/tapon/user/upload_avatar', data)
}

export function taponUser(data: { gender: number; birthday: string; avatar: string }) {
  return request.post('/tapon/user', data)
}

export function taponUserCostList(params: Page) {
  return request.get<TaponUserCostList>('/tapon/user/cost_list', params)
}

export function taponUserBoughtList(params: Page) {
  return request.get<TaponUserOrderList>('/tapon/user/order_list', params)
}

export function taponUserWealthCouponList(params: { valid: number; type: string } & Page) {
  return request.get<TaponUserWealthCouponList>('/tapon/user/wealth_coupon_list', params)
}

export function taponBookcase(params: Page) {
  return request.get<TaponBookcase>('/tapon/bookcase', params)
}

export function webProducts(params = {}) {
  return request.get<WebProducts>('/web/v3/products', params)
}

export function webOrder(
  data: {
    product_id: number | string
    payment_gateway: string
    options: {
      return_url: string
      cancel_url: string
    }
    web_tracks: {
      channel: string
      case_time: string
      times: number
    }
  },
  config: AxiosRequestConfig,
) {
  return request.post<{
    id: number
    pay_params: {
      redirect: string
    }
  }>('/web/order', data, config)
}

export function webMermberCheck(data: { member_id: string }) {
  return request.post<WebMemberCheck>('/web/member_check', data)
}

export function webMemberSearch(params: { q: string }) {
  return request.get<WebMemberSearch>('/web/member_search', params)
}

// Airwallex支付回调
export function asyncAirNotify(params?: any) {
  return request.get('/web/airwallex_notify', params)
}

// Airwallex支付保存的卡列表
export function paymentConsents(params?: any) {
  return request.get('/web/airwallex/payment_consents', params)
}

// 优惠券列表
export function getCouponList(params: { product_id: number }) {
  return request.get('/web/member/discount_coupons', params)
}

// 本地钱包 支付
export function payConfirm(intent_id: string, data: any) {
  return request.post<WebMemberCheck>(`/web/airwallex/payment_intents/${intent_id}/confirm`, data)
}
// 获取支付选项
export function localWalletPaymentStatus() {
  return request.get(`/web/airwallex/local_wallet_payment_status`)
}
