import cookies from 'js-cookie'

export function getCookieToken() {
  return cookies.get('token') || ''
}

export function setCookieToken(token: string) {
  cookies.set('token', token)
}

export function setStorage<T>(name: string, value: T) {
  localStorage.setItem(name, JSON.stringify(value))
}

export function getStorage<T>(name: string) {
  const res = localStorage.getItem(name)
  if (res) return JSON.parse(res) as T
  else return undefined
}

export function removeStorage(name: string) {
  localStorage.removeItem('name')
}

export function clearStorage() {
  cookies.remove('token')
  localStorage.clear()
}
