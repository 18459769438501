import { Page, TaponBookUnlimited } from './types.d'
import { HomeTabs, TaponBanner, TaponHome, TaponHomeMoreBooks } from './types'
import request from '../utils/request'

export const queryHomeTabs = () => {
  return request.get<HomeTabs>('/tapon/home_tabs')
}

export const queryTaponHome = (params = {}) => {
  return request.get<TaponHome>('/tapon/home', params)
}

export const queryTaponHomeMoreBooks = (params = {}) => {
  return request.get<TaponHomeMoreBooks>('/tapon/home/more_books', params)
}

export const taponBanner = (params = {}) => {
  return request.get<TaponBanner>('/tapon/banners', params)
}

export const taponBookUnlimited = (params: Page) => {
  return request.get<TaponBookUnlimited>('/tapon/book/unlimited', params)
}

// 获取服务器时间
export const taponServerTime = () => {
  return request.get<{ timestamp: number }>('/tapon/settings/iso_time')
}

// 获取官网签到奖励
export const getWebCheckin = () => {
  return request.post('/web/checkin', {}, { noMessage: true })
}
