import { setStorage } from '@/utils/storage'
import { useMemo, useState } from 'react'
import i18n from '@/utils/i18n'
import { getLanguage } from '@/utils/language'

import thTH from '@/locales/antd/en-US'
import arAR from '@/locales/antd/en-US'
import frFR from '@/locales/antd/en-US'

// import es from '@/locales/antd/es-ES'
// import ko from '@/locales/antd/ko-KR'
// import id from '@/locales/antd/id-ID'
import enUS from 'antd-mobile/es/locales/en-US'
import esES from 'antd-mobile/es/locales/es-ES'
import koKR from 'antd-mobile/es/locales/ko-KR'
import idID from 'antd-mobile/es/locales/id-ID'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import zhTW from 'antd-mobile/es/locales/zh-TW'

import { setDefaultConfig } from 'antd-mobile'
import { Locale } from 'antd-mobile/es/locales/base'
import { track } from '@/utils/track'

export function useLanguage() {
  const [getter, setter] = useState<string>(getLanguage())

  const languageNameGetter = useMemo(() => {
    const langs = {
      en: 'English',
      es: 'Español',
      ko: '한국어',
      id: 'Bahasa Indonesia',
      pt: 'Português',
      th: 'ภาษาไทย',
      hans: '中文（简体）',
      hant: '中文（繁體）',
      ar: 'اللغة العربية',
      fr: 'Français',
      de: 'Deutsch',
      ja: '日本語',
    }
    return langs[getter]
  }, [getter])
  return {
    language: getter,
    languageName: languageNameGetter,
    setLanguage(language: string) {
      track('personalcenter-languagepage-click', { language })
      const isZh = ['hans', 'hant'].includes(language)
      if (process.env.env === 'production') {
        const url = `https://${language === 'en' ? 'www' : isZh ? `zh-${language}` : language}.tapon.com/`
        window.location.href = url
      } else if (process.env.env === 'test') {
        let url = `https://tapon-h5-${language}-test.joyread.cc/`
        if (language === 'en') url = 'https://tapon-h5-test.joyread.cc/'
        else if (isZh) url = `https://tapon-h5-zh-${language}-test.joyread.cc/`
        window.location.href = url
      } else {
        const antdLocale = {
          en: enUS,
          es: esES,
          ko: koKR,
          id: idID,
          pt: esES,
          th: thTH,
          hans: zhCN,
          hant: zhTW,
          ar: arAR,
          fr: frFR,
        }[getLanguage()] as Locale
        setter(language)
        setStorage('language', language)
        i18n.changeLanguage(language)
        setDefaultConfig({ locale: antdLocale })
      }
    },
  }
}
