import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './views/App'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'

import store from '@/store'
import { Provider } from 'react-redux'

import '@/utils/i18n'

import '@/assets/js/iconpark_es2015.js'

import { ConfigProvider } from 'antd-mobile'
// import en from '@/locales/antd/en-US'
// import es from '@/locales/antd/es-ES'
// import ko from '@/locales/antd/ko-KR'
// import id from '@/locales/antd/id-ID'
import enUS from 'antd-mobile/es/locales/en-US'
import esES from 'antd-mobile/es/locales/es-ES'
import koKR from 'antd-mobile/es/locales/ko-KR'
import idID from 'antd-mobile/es/locales/id-ID'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import zhTW from 'antd-mobile/es/locales/zh-TW'
import jaJP from 'antd-mobile/es/locales/ja-JP'

import { setStorage } from './utils/storage'
import { getLanguage } from './utils/language'
import { Locale } from 'antd-mobile/es/locales/base'
import { BrowserRouter } from 'react-router-dom'

import { setDefaultConfig } from 'antd-mobile'
import 'uno.css'
import { FastClick } from 'fastclick'
import { AliveScope } from 'react-activation'
// 使用fastclick阻止双击
// const { focus, } = FastClick.prototype
// FastClick.prototype.focus = (elem: any /* EventTarget | Element */) => {
//   focus.call(this, elem)
//   elem.focus()
// }

function init() {
  const e = 375
  if (screen.width !== e) {
    const metaEl = document.querySelector("meta[name='viewport']")
    let t = screen.width
    let n = 1
    if (screen.width === 1080) {
      t = 360
    }
    if (/Android\s4\.3.*UCBrowser\/11\.1\.0\.870.*/.test(window.navigator.userAgent)) {
      t = 360
    }
    n = t / e
    metaEl?.setAttribute(
      'content',
      'width=' +
        e +
        ',initial-scale=' +
        n +
        ',maximum-scale=' +
        n +
        ',minimum-scale=' +
        n +
        ',user-scalable=no,viewport-fit=cover',
    )
  }
  setStorage('language', process.env.LANG || 'en')
  if (import.meta.env.MODE === 'test') {
    const script = document.createElement('script')
    script.src = 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js'
    script.onload = function () {
      const vConsole = new window.VConsole()
    }
    document.head.appendChild(script)
  }
}

function renderApp() {
  if (getLanguage() === 'ar') {
    document.body.classList.add('direction-rtl')
  }
  const antdLocale = {
    en: enUS,
    es: esES,
    ko: koKR,
    id: idID,
    pt: esES,
    th: enUS,
    hans: zhCN,
    hant: zhTW,
    ar: enUS,
    fr: enUS,
    de: enUS,
    ja: jaJP,
  }[getLanguage()] as Locale
  setDefaultConfig({ locale: antdLocale })
  if (['production'].includes(process.env.VITE_MODE!)) {
    Sentry.init({
      dsn: 'https://125e8e5af0da459488c5900edb9d00d1@o4504093839917056.ingest.sentry.io/4504218657751040',
      environment: process.env.VITE_MODE,
      release: process.env.SENTRY_VERSION || '0.0.1',
      tracesSampleRate: 1.0,
    })
  }

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={ store }>
        <ConfigProvider locale={ antdLocale }>
          <AliveScope>
            <App />
          </AliveScope>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
    () => {
      // 阻止双击事件
      FastClick.attach(document.body)
    },
  )
}

init()

renderApp()

// https://github.com/ftlabs/fastclick/issues/548
// https://github.com/ftlabs/fastclick/issues/549

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
