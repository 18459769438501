import { getStorage } from '@/utils/storage'
import { isZh } from './constant'
import { getLanguage } from './language'
import { capitalize } from '@/utils/index'

export function track(id: string, params?: { [key: string]: string | number }) {
  const lang = getLanguage()
  const config = {
    region: isZh ? 'tzh' : `t${getLanguage()}`,
    uid: getStorage('uid') || 0,
    pkg: 0,
    platform: 'web',
    version: '',
    ...params,
  }
  if (isZh) config['lang-script'] = capitalize(lang)
  ;(window as any)._zaq.push(['_trackEvent', id, JSON.stringify(config)])
}
