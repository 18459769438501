import React, { useEffect } from 'react'
import { TabBar } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '@/store/layout'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Store } from '@/store'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@/hooks/useQuery'
import { useLanguage } from '@/hooks/useLanguage'

function Layout() {
  const activeTab = useSelector((store: Store) => store.layout.activeTab)
  const { t } = useTranslation('translation')
  const { active } = useQuery()
  const { language } = useLanguage()
  function getIconColor(key: string) {
    return key === activeTab ? `${language === 'ar' ? '#fd6b0a' : '#FE4A32'}` : '#BFBFBF'
  }
  const tabs = [
    {
      key: '/library',
      title: t('library'),
      icon: <iconpark-icon name='shujia' color={getIconColor('/library')} />,
    },
    {
      key: '',
      title: t('home'),
      icon: <iconpark-icon name='shouye' color={getIconColor('')} />,
    },
    {
      key: '/category',
      title: t('genres'),
      icon: <iconpark-icon name='fenlei' color={getIconColor('/category')} />,
    },
    {
      key: '/mine',
      title: t('profile'),
      icon: <iconpark-icon name='wode' color={getIconColor('/mine')} />,
    },
  ]
  const location = useLocation()
  const defaultActiveKey = location.pathname
  useEffect(() => {
    const keys = tabs.map(v => v.key)
    const defaultActiveKey = location.pathname
    dispatch(setActiveTab({ key: keys.includes(defaultActiveKey) ? defaultActiveKey : '' }))
  }, [location])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // console.log(defaultActiveKey)
  if (active && defaultActiveKey === '/sso') return <Outlet />
  return (
    <>
      <Outlet />
      <TabBar
        defaultActiveKey={defaultActiveKey}
        activeKey={activeTab}
        onChange={val => {
          dispatch(setActiveTab({ key: val }))
          navigate(val)
        }}
        className='fixed -bottom-1 w-screen bg-white shadow-sm-top z-50'
      >
        {tabs.map(item => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </>
  )
}

export default Layout
