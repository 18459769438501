import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { track } from '@/utils/track'

import './bulb.scss'

function Bulb(props) {
  const { bulbImg } = props
  const [visible, setVisible] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const whiteRouter = ['/sso', '/language', '/c-air']
  const targetPath = '/c'

  useEffect(() => {
    setVisible(false)

    if (targetPath !== pathname && !whiteRouter.includes(pathname) && !pathname.includes('/bookDetail/')) {
      setVisible(true)
      track('taponh5-homesmallbulb-view')
    }
  }, [pathname])

  return (
    <>
      <div className='bulb-wrap'>
        {visible && (
          <img
            onClick={ () => {
              track('taponh5-homesmallbulb-click')
              navigate('/c')
            } }
            src={ bulbImg }
            alt=''
          />
        )}
      </div>
    </>
  )
}

export default Bulb
