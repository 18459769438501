import React, { Suspense } from 'react'
import { RouteProps } from './types'
import Layout from '@/layout/Layout'
import Loading from '@/components/Loading'
import Global from '@/layout/global'

// 一个动态导入
// 为什么不写成lazyLoad(path: string)
// https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
function lazyLoad(Comp: React.LazyExoticComponent<any>): React.ReactNode {
  return (
    <Suspense
      fallback={
        <div className='flex flex-center w-screen h-screen bg-f5'>
          <Loading />
        </div>
      }>
      <Global>
        <Comp />
      </Global>
    </Suspense>
  )
}

const routeConfig: RouteProps[] = [
  {
    path: '',
    element: <Layout />,
    meta: { keepAlive: true },
    children: [
      {
        path: '',
        element: lazyLoad(React.lazy(() => import('../views/home/Home'))),
      },
      {
        path: '/sso',
        element: lazyLoad(React.lazy(() => import('../views/login/sso'))),
      },
      {
        path: '/mine',
        element: lazyLoad(React.lazy(() => import('../views/mine/Mine'))),
      },
      {
        path: '/category',
        element: lazyLoad(React.lazy(() => import('../views/category/Category'))),
      },
      {
        path: '/library',
        element: lazyLoad(React.lazy(() => import('../views/library/Library'))),
      },
    ],
  },
  {
    path: 'typePage',
    meta: { keepAlive: true },
    element: lazyLoad(React.lazy(() => import('../views/home/Books'))),
  },
  {
    path: '/setting',
    element: lazyLoad(React.lazy(() => import('../views/mine/Setting'))),
    meta: { auth: true },
  },
  {
    path: '/profile',
    element: lazyLoad(React.lazy(() => import('../views/mine/Profile'))),
    meta: { auth: true },
  },
  {
    path: '/bonus',
    element: lazyLoad(React.lazy(() => import('../views/mine/Bonus'))),
    meta: { auth: true },
  },
  {
    path: '/bookDetail',
    element: lazyLoad(React.lazy(() => import('../views/book/Book'))),
  },
  {
    path: '/bookDetail/:bookId',
    element: lazyLoad(React.lazy(() => import('../views/book/Chapter'))),
  },
  {
    path: '/search',
    element: lazyLoad(React.lazy(() => import('../views/search/Search'))),
  },
  {
    path: '/c',
    element: lazyLoad(React.lazy(() => import('../views/recharge/Recharge'))),
  },
  {
    path: '/c-air',
    element: lazyLoad(React.lazy(() => import('../views/recharge/airwallex'))),
  },
  {
    path: '/c-success',
    element: lazyLoad(React.lazy(() => import('../views/recharge/PaySuccess'))),
  },
  {
    path: '/language',
    element: lazyLoad(React.lazy(() => import('../views/mine/Language'))),
  },
  // {
  //   path: '/sso',
  //   element: lazyLoad(React.lazy(()=>import('../views/login/Login'))),
  // },
  {
    path: '*',
    element: lazyLoad(React.lazy(() => import('../views/home/Home'))),
  },
]

export default routeConfig
