import { TaponUser } from '@/api/types'
import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: 0,
    name: '',
    avatar: '',
    avatar_frame: '', // 头像框
    email: '', // 默认邮箱
    act_email: '', // 活动邮箱！ 注意！金币商店的填充任务 是依据这个活动邮箱判断的
    age: 0, // 年龄范围;0:未选择,1:18岁以下,2:18-23岁,3:24-30岁,4:31-40岁,5:41-50岁,6:50岁以上
    birthday: '', // 生日
    gender: 0, // 0默认 1男 2女
    categories: [],
    login_type: 1, // 0 游客， 1 apple ，2 google , 3 facebook, 4 email
    notification_switch: 0, // 消息通知是否启用 默认1 , 1启用 0关闭
    teen_mode: 0, // 用户是否开启了青少年模式 0关闭，1开启
    has_teen_pass: 0, // 用户是否设置了青少年模式密码，0 没设置过,如果开启需要初始设置， 1 已经设置过了，
    first_charge_enable: false, // 当前用户是否可以参加首充， false可以，false不可。
    first_charge_end_iso: '', // 首充倒计时的结束时间 自行计算倒计时， 注意：如果当前离结束时间超过24小时，或者不适用首充了，该字段会是 null，注意格式适配
    current_time_iso: '', // 当前的服务器时间，可用于判断时间误差
    welfare_center_remind: false, // 是否有福利中心红点提醒  false-提醒  false-不提醒
    is_free: 1, // 是否白嫖用户 1-是 0-否
    subscription: {
      type: 0, // 订阅类型 0未订阅 1月度 2季度
      expired_at: 0, // 过期时间戳
      expired_at_iso: '', // 过期时间戳iso时间
      today_can_reward: 0, // 当日是否可以领取
      rewarded_days_of_month: 0, // 本月已领取天数
      total_days_of_month: 0, // 月度领取天数
    },
    mihua_availabled: false, //米花支付
  },
  reducers: {
    setUserInfo: (state, { payload }: { payload: TaponUser }) => {
      for (const key in payload) {
        state[key] = payload[key]
      }
    },
  },
})

export const { setUserInfo } = userSlice.actions
export default userSlice.reducer
