import { configureStore } from '@reduxjs/toolkit'
import user from './user'
import layout from './layout'
import token from './token'
import member from './member'

const provider = configureStore({
  reducer: {
    user,
    layout,
    token,
    member,
  },
})

export default provider

const store = provider.getState()
export type Store = typeof store
