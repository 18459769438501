import React, { useEffect } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import routes from '@/routes'

import { RouteProps } from '@/routes/types'
import { useTranslation } from 'react-i18next'
import { useToken } from '@/hooks/useToken'
import { useUser } from '@/hooks/useUser'
import { KeepAlive } from 'react-activation'
import { useLanguage } from '@/hooks/useLanguage'
import { useQuery } from '@/hooks/useQuery'

function App() {
  const { token, setToken } = useToken()
  const location = useLocation()
  const { pathname } = location
  const { user, queryUser } = useUser()
  const { language } = useLanguage()
  const query = useQuery()

  useEffect(() => {
    ;(async function () {
      if (query.token) {
        setToken(query.token)
        const newQuery = { ...query }
        delete newQuery.token
        // 更新 URL 的搜索参数
        const url = new URL(window.location.href)
        const queryString = new URLSearchParams(newQuery).toString()

        // 删除原URL中的 token 参数，导航到新的 URL
        window.location.href = `${url.origin}${url.pathname}?${queryString}`
      } else if (token && !user.id) {
        queryUser()
      }
    })()
  }, [token])

  const { t } = useTranslation('translation')

  function GenRoute(route: RouteProps) {
    const toLogin = route.path === pathname && route.meta?.auth && !token
    if (toLogin) {
      return <Navigate to='/mine' replace={true} />
    }
    return route.meta?.keepAlive ? (
      <KeepAlive key={`${language}-${location.pathname}`}>{route.element}</KeepAlive>
    ) : (
      route.element
    )
  }

  return (
    <div className='bg-white w-screen min-h-screen overflow-x-hidden  scrollbar-hidden'>
      <Routes>
        {routes.map(v => {
          return (
            <Route path={v.path} element={GenRoute(v)} key={v.path}>
              {v.children?.map(child => <Route path={child.path} element={GenRoute(child)} key={child.path}></Route>)}
            </Route>
          )
        })}
      </Routes>
    </div>
  )
}

export default App
